import React from "react";
import Helmet from "react-helmet";
import {graphql, Link, useStaticQuery} from "gatsby";
import styled from "react-emotion";

import {miniPadding} from "../styles/layout";
import Header from "./Header";
import "normalize.css/normalize.css";
import "../styles/base-style";

const Outer = styled("div")({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

const OuterContent = styled("div")({
  padding: "4rem 3rem 6rem",
  flex: "auto",
  ...miniPadding,
});

const InnerContent = styled("div")({
  maxWidth: "50rem",
  margin: "0 auto",
});

const Footer = styled("div")({
  fontSize: "0.8rem",
  textAlign: "center",
  marginTop: "2rem",
  padding: "1rem 2rem",
  backgroundColor: "rgba(0,0,0,0.5)",
  boxShadow: "0 2rem 1rem -2rem rgba(0,0,0,0.5) inset",
});

const style = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  textDecoration: "none",
  color: "rgba(255,255,255,0.5)",
  "&:hover": {color: "rgba(255,255,255,0.8)"},
};

const FooterLink = styled("a")(style);
const FooterInPageLink = styled(Link)(style);

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        content
      }
    }
  }
`;

const Layout = ({children, title, lang}) => {
  const data = useStaticQuery(query);
  return (
    <Outer lang="de">
      <Helmet
        title={title || data.site.siteMetadata.title}
        meta={[{name: "description", content: data.site.siteMetadata.content}]}
        htmlAttributes={{lang: "de"}}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <OuterContent>
        <InnerContent lang={lang}>{children}</InnerContent>
      </OuterContent>
      <Footer>
        <FooterInPageLink to="/barrierefreiheit">Barrierefreiheit</FooterInPageLink>
        <FooterLink href="https://www.hu-berlin.de/de/hu/impressum/datenschutzerklaerung">
          Datenschutzerklärung
        </FooterLink>
      </Footer>
    </Outer>
  );
};

export default Layout;
