import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "react-emotion";
import {Col} from "../../styles/basics";
import {breakPoints} from "../../styles/layout";

const Supporter = ({
  href,
  imgData: {
    childImageSharp: {fluid},
  },
  alt,
  title,
  scale = 1,
}) => (
  <a
    href={href}
    rel="noreferrer noopener"
    target="_blank"
    css={{
      display: "block",
      marginLeft: "1rem",
      marginRight: "1rem",
      flex: `${fluid.aspectRatio * 100 * scale}px 1 1`,
      "&:not(:last-child)": {marginBottom: "0.2rem"},
      [breakPoints.mini]: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
      },
    }}
  >
    <Img
      fluid={{
        ...fluid,
        sizes: `(max-width: 1000px) ${(33 / 3) * fluid.aspectRatio * scale}vw, ${
          150 * fluid.aspectRatio * scale
        }px`,
      }}
      alt={alt}
      title={title}
    />
  </a>
);

export const supporterImage = graphql`
  fragment supporterImage on File {
    childImageSharp {
      fluid(maxHeight: 150) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Container = styled("div")({
  flex: "auto",
  display: "flex",
  alignItems: "flex-end",
  maxWidth: "30rem",
  width: "100%",
});

const query = graphql`
  query Supporters {
    dfg: file(relativePath: {eq: "components/Header/logo-dfg.png"}) {
      ...supporterImage
    }
    hu: file(relativePath: {eq: "components/Header/logo-hu.png"}) {
      ...supporterImage
    }
    muenster: file(relativePath: {eq: "components/Header/logo-muenster.png"}) {
      ...supporterImage
    }
  }
`;

const Supporters = () => {
  const data = useStaticQuery(query);
  return (
    <Col css={{alignItems: "center"}}>
      <Container>
        <Supporter href="http://www.dfg.de" imgData={data.dfg} alt="DFG" title="DFG" scale={0.66} />
        <Supporter
          href="https://www.hu-berlin.de/"
          imgData={data.hu}
          alt="HU Berlin"
          title="HU Berlin"
        />
        <Supporter
          href="https://www.uni-muenster.de"
          imgData={data.muenster}
          alt="Uni Münster"
          title="Uni Münster"
        />
      </Container>
    </Col>
  );
};

export default Supporters;
