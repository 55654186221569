import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "react-emotion";
import colors from "../../styles/colors";
import {fonts} from "../../styles/typo";
import {miniPadding} from "../../styles/layout";
import Flags from "./Flags";
import Supporters from "./Supporters";
import Navigation from "./Navigation";

const headerBreak = "@media(max-width: 700px)";

const logoQuery = graphql`
  query {
    logo: file(relativePath: {eq: "components/Header/logo.png"}) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const Logo = () => {
  const {logo} = useStaticQuery(logoQuery);
  const {fixed} = logo.childImageSharp;
  return (
    <Img
      style={{
        width: "10rem",
        height: `${(fixed.height / fixed.width) * 10}rem`,
        flex: "none",
      }}
      fixed={fixed}
      alt="Choice Logo"
    />
  );
};

const StyledHeader = styled("header")({
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
  [headerBreak]: {
    flexDirection: "column",
    textAlign: "center",
  },
});

const MainSlogan = styled("div")({
  ...fonts.heading,
  fontSize: "3rem",
});

const SloganSub = styled("div")({
  fontSize: "0.8rem",
  color: colors.brandDeemph,
  textTransform: "uppercase",
});

const MainHeader = () => (
  <StyledHeader>
    <Logo />
    <div css={{marginLeft: "4rem", [headerBreak]: {marginLeft: 0, marginTop: "2rem"}}}>
      <MainSlogan>Schulwahl und sozio-kulturelle Passung</MainSlogan>
      <SloganSub>
        Einzelschulwahl, Schulqualität und Schulträgerspezifik unter besonderer Berücksichtigung von
        Schulen in freier Trägerschaft
      </SloganSub>
    </div>
  </StyledHeader>
);

const OuterAll = styled("div")({
  boxShadow: "0 0.5rem 2rem rgba(0,0,0,0.3)",
});

const OuterHeader = styled("div")({
  padding: "2rem 3rem 1rem",
  backgroundColor: "#fff",
  color: colors.brandDark,
  ...miniPadding,
});

const InnerHeader = styled("div")({
  maxWidth: "50rem",
  margin: "0 auto",
});

const Header = () => (
  <OuterAll>
    <OuterHeader>
      <Flags />
      <InnerHeader>
        <MainHeader />
        <Supporters />
      </InnerHeader>
    </OuterHeader>
    <Navigation />
  </OuterAll>
);

export default Header;
