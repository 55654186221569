import React from "react";
import {Link, graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "react-emotion";

const Flag = ({to, imgData, alt, title}) => (
  <Link
    to={to}
    css={{
      display: "block",
      "&:not(:last-child)": {marginRight: "0.2rem"},
    }}
  >
    <Img
      style={{
        width: `${(imgData.width / imgData.height) * 1.2}rem`,
        height: "1.2rem",
      }}
      fixed={imgData}
      alt={alt}
      title={title}
    />
  </Link>
);

const query = graphql`
  query Flags {
    flagTu: file(relativePath: {eq: "components/Header/flag-tu.png"}) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    flagUk: file(relativePath: {eq: "components/Header/flag-uk.png"}) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const FlagContainer = styled("div")({
  position: "absolute",
  top: "0.5rem",
  right: "0.5rem",
  fontSize: 1,
  display: "flex",
});

const Flags = () => {
  const data = useStaticQuery(query);
  return (
    <FlagContainer>
      <Flag
        to="/en/"
        imgData={data.flagUk.childImageSharp.fixed}
        alt="English"
        title="English Version"
      />
      <Flag
        to="/tur/"
        imgData={data.flagTu.childImageSharp.fixed}
        alt="Turkish"
        title="Turkish Version"
      />
    </FlagContainer>
  );
};

export default Flags;
