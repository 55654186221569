import React from "react";
import {Link, graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "react-emotion";
import {fonts} from "../../styles/typo";
import colors from "../../styles/colors";
import {breakPoints} from "../../styles/layout";

const bannerQuery = graphql`
  query {
    logo: file(relativePath: {eq: "components/Header/banner.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const BannerBg = () => {
  const {logo} = useStaticQuery(bannerQuery);
  const {fluid} = logo.childImageSharp;
  return (
    <Img
      css={{top: 0, left: 0, right: 0, bottom: "0.4rem"}}
      style={{position: "absolute"}}
      fluid={{...fluid, sizes: "(max-width: 800px) 800px, 1600px"}}
      alt=""
    />
  );
};

const bannerBreak = "@media(max-width: 700px)";

const StyledLink = styled(Link)({
  ...fonts.heading,
  backgroundColor: "#fff",
  color: colors.brand,
  padding: "0.4rem 1.5rem 0.25rem",
  boxShadow: "0 0.1rem 0.8rem rgba(0,0,0,.5)",
  fontSize: "1.8rem",
  letterSpacing: "0.03rem",
  marginLeft: "0.1rem",
  marginRight: "0.1rem",
  marginBottom: "0.2rem",
  transitionProperty: "background-color, color",
  ":hover": {
    backgroundColor: colors.brandDark,
    color: "#fff",
  },
  [bannerBreak]: {
    fontSize: "1.5rem",
  },
  [breakPoints.mini]: {
    flex: "auto",
    textAlign: "center",
  },
});

const Container = styled("div")({
  position: "relative",
  backgroundColor: "#fff",
});

const NavOuterContainer = styled("div")({
  position: "relative",
  top: "1rem",
  paddingTop: ["3vw", "calc(5vw - 1rem)"],
  paddingLeft: "3rem",
  paddingRight: "3rem",
  [bannerBreak]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});
const NavInnerContainer = styled("nav")({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "50rem",
  margin: "0 auto",
  justifyContent: "space-between",
  [bannerBreak]: {
    justifyContent: "center",
  },
});

const Navigation = () => (
  <Container>
    <BannerBg />
    <NavOuterContainer>
      <NavInnerContainer>
        <StyledLink to="/" className="main-navigation-link">
          Startseite
        </StyledLink>
        <StyledLink to="/results/" className="main-navigation-link">
          Ergebnisse
        </StyledLink>
        <StyledLink to="/publications/" className="main-navigation-link is-long">
          Vorträge & Publikationen
        </StyledLink>
        <StyledLink to="/team/" className="main-navigation-link">
          Team
        </StyledLink>
        <StyledLink to="/contact/" className="main-navigation-link">
          Kontakt
        </StyledLink>
      </NavInnerContainer>
    </NavOuterContainer>
  </Container>
);

export default Navigation;
